import React from "react";
import LeftNavBar from "../../components/LeftNavBar";
import SearchBar from "../../components/searchBar";
import ChatHistory from "../../components/chatSectionComponents/chatHistory";
import MyData from "../../components/chatSectionComponents/myData";
import PdfViewer from "../../components/chatSectionComponents/pdfViewer";
import styles from "./chat.module.css";
import useChat from "./useChat";
import { Outlet } from "react-router-dom";

const Chat = () => {
  const { isShowingPDF, panelWidth, handleMouseDown } = useChat();

  return (
    <div className={styles.container}>
      <div>
        <LeftNavBar />
      </div>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <SearchBar />
        </div>
        <div className={styles.content}>
          <div
            className={`${styles.leftPanel} ${
              isShowingPDF ? styles.leftPanelHidden : styles.leftPanelShow
            }`}
            style={{ width: panelWidth }}
          >
            <div className={styles.resizer} onMouseDown={handleMouseDown} />
            <div className={styles.asideContainer}>
              <ChatHistory />
              <MyData />
            </div>
          </div>
          <div className={styles.rightPanel}>
            <Outlet />
          </div>
          {isShowingPDF && (
            <div
              style={{
                border: "1px solid #EAECF0",
                width: "100% ",
                overflow: "auto",
              }}
            >
              <PdfViewer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
