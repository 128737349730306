import React from "react";
import styles from "../styles/avatar.module.css";

const Avatar = ({
  src,
  alt = "avatar",
  width = "100%",
  height = "auto",
  borderColor = "transparent",
  customStyle = {},
}) => {
  const defaultStyle = {
    width,
    height,
    border: `2px solid ${borderColor}`,
  };

  return (
    <img
      src={src}
      alt={alt}
      style={{ ...defaultStyle, ...customStyle }}
      className={styles.avatar}
    />
  );
};

export default Avatar;
