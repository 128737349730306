import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDocs: [],
  selectedDocs: [],
  selectedCategories: [],
  isShowingPDF: false,
  fileLink: "",
  targetPage: null,
  targetContent: "",
};

const docsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {
    setAllDocs(state, action) {
      state.allDocs = action.payload;
    },
    setSelectDocs(state, action) {
      state.selectedDocs = action.payload;
    },
    setSelectCategories(state, action) {
      state.selectedCategories = action.payload; // Set selected categories
    },
    setShowPdf(state, action) {
      const link = action.payload;
      state.fileLink = link;
      state.isShowingPDF = true;
    },
    setClosePdf(state, action) {
      state.fileLink = "";
      state.isShowingPDF = false;
      state.targetContent = "";
      state.targetPage = null;
    },
    setTargets(state, action) {
      state.targetContent = action.payload.targetContent;
      state.targetPage = action.payload.targetPage;
    },
  },
});

export const {
  setSelectDocs,
  setSelectCategories,
  setClosePdf,
  setShowPdf,
  setTargets,
  setAllDocs,
} = docsSlice.actions;

export default docsSlice.reducer;
