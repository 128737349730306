import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allStocks: {},
  selectedStock: null,
};

const stocksSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSelectedStock(state, action) {
      state.selectedStock = action.payload;
    },
    setAllStockTickers(state, action) {
      state.allStocks = action.payload;
    },
  },
});

export const { setSelectedStock, setAllStockTickers } = stocksSlice.actions;
export default stocksSlice.reducer;
