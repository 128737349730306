import React from "react";
import styles from "./notFound.module.css";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.content}>404 | Not Found</p>
      <Button buttonText="Go Home" onClick={handleGoHome} />
    </div>
  );
};

export default NotFound;
