import styles from "./chatHeader.module.css";
import { useSelector } from "react-redux";

const ChatHeader = () => {
  const { activeChat } = useSelector((state) => state.chats);
  return (
    <div className={styles.container_header}>
      <span className={styles.title}>
        {activeChat.chatName ? activeChat.chatName : "New Chat"}
      </span>
    </div>
  );
};

export default ChatHeader;
