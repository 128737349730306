import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./uploadDocument.module.css";
import SelectionOption from "../SelectOption";
import { FILE_OPTIONS } from "../../utils/constants/common";
import { MdDelete } from "react-icons/md";

const FileItem = ({
  data,
  onSelect,
  onTypeChange,
  onStockChange,
  onDeleteFile,
}) => {
  const searchRef = useRef(null);
  const { allStocks } = useSelector((state) => state.stocks);
  const [searchText, setSearchText] = useState("");
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsFocused(false); // Hide suggestions if clicking outside the search area
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (searchText) {
      const filtered = Object.keys(allStocks)
        .filter((key) => {
          const [symbol, name] = allStocks[key];
          return (
            key.toLowerCase().includes(searchText.toLowerCase()) || // Check the key (ID)
            symbol.toLowerCase().includes(searchText.toLowerCase()) || // Check the symbol
            name.toLowerCase().includes(searchText.toLowerCase()) // Check the name
          );
        })
        .map((key) => ({
          ticker: key,
          country: allStocks[key][0],
          company_name: allStocks[key][1],
        }));
      setFilteredStocks(filtered); // Store the filtered results
    } else {
      setFilteredStocks([]); // Reset if input is cleared
    }
  }, [searchText, allStocks]);
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
  };

  return (
    <div
      className={`${styles.file_wrapper} ${data.isSelected && styles.selected}`}
    >
      <div className={styles.file_options}>
        <input
          type="checkbox"
          checked={data.isSelected}
          onChange={(e) => onSelect(e.target.checked)}
          id={data.file.name}
          className={styles.checkbox}
        />
        <label htmlFor={data.file.name} className={styles.file_name}>
          {data.file.name}
        </label>
      </div>
      <div className={styles.file_options}>
        <div className={styles.stock_search} ref={searchRef}>
          {data.stock ? (
            <div
              style={{
                fontSize: "14px",
                position: "relative",
                border: "1px solid gray",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  maxWidth: "90%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data.stock.company_name}
              </span>
              <span
                style={{
                  position: "absolute",
                  right: 5,
                  top: "50%",
                  transform: "translate(0,-50%)",
                  cursor: "pointer",
                }}
                onClick={() => onStockChange(null)}
              >
                X
              </span>
            </div>
          ) : (
            <input
              type="text"
              style={{
                padding: "5px",
                outline: "none",
                border: "1px solid gray",
                borderRadius: "5px",
                outlineColor: "gray",
              }}
              className={styles.stock_search_input}
              value={searchText}
              onChange={handleSearchChange}
              onFocus={() => setIsFocused(true)} // Show suggestions on focus
              placeholder="Search stocks..."
            />
          )}
          {isFocused &&
            !data.stock &&
            (filteredStocks.length > 0 ? (
              <div className={styles.resultsContainer}>
                {filteredStocks.map((result, index) => (
                  <div
                    key={result.ticker}
                    className={`${styles.resultItem}`}
                    onClick={() => {
                      onStockChange(result);
                    }}
                  >
                    <span className={styles.company_name}>
                      {result?.company_name} ({result.country})
                    </span>
                    <span className={styles.ticker_name}>
                      {" "}
                      {result?.ticker}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              searchText.length > 0 && (
                <div className={styles.resultsContainer}>Not found</div>
              )
            ))}
        </div>
        <SelectionOption
          options={FILE_OPTIONS}
          selectedValue={data.type}
          onChange={(value) => onTypeChange(value)}
          customStyle={
            data.isSelected && {
              backgroundColor: "#4F46E50D",
              border: "1px solid lightgray",
            }
          }
        />
        <MdDelete
          onClick={onDeleteFile}
          color="gray"
          size={20}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default FileItem;
