import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeChat: {
    isActive: false,
    chatId: null,
    chatName: "",
  },
  allChats: [],
  messages: [],
  isChatLoaded: false,
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,

  reducers: {
    setAllChats: (state, action) => {
      state.allChats = action.payload;
      state.isChatLoaded = true;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addUserMessage: (state, action) => {
      // Push the user message directly into messages array

      state.messages.push({
        role: "user",
        content: action.payload,
      });
    },
    startStreamingBotMessage: (state) => {
      // Add a placeholder for the bot message to start streaming
      state.messages.push({
        role: "bot",
        content: "",
        streaming: true,
        sources: [],
      });
    },
    appendToStreamingBotMessage: (state, action) => {
      // Find the last bot message in the array and append to it
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.role === "bot" && lastMessage.streaming) {
        lastMessage.content = action.payload;
      }
    },
    finalizeBotMessage: (state, action) => {
      // Find the last bot message and mark it as complete
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.role === "bot" && lastMessage.streaming) {
        lastMessage.streaming = false;
        lastMessage.sources = action.payload;
      }
    },

    clearChatMessages: (state) => {
      // Clear all messages for the current chat
      state.messages = [];
    },
    setActiveChat: (state, action) => {
      state.activeChat.isActive = action.payload.isActive;
      state.activeChat.chatName = action.payload.chatName;
      state.activeChat.chatId = action.payload.chatId;
    },
  },
});

export const {
  clearChatMessages,
  finalizeBotMessage,
  appendToStreamingBotMessage,
  startStreamingBotMessage,
  addUserMessage,
  setMessages,
  setAllChats,
  setActiveChat,
} = chatsSlice.actions;

export default chatsSlice.reducer;
