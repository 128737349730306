import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./searchBar.module.css";
import { IoClose } from "react-icons/io5";
import { setAllStockTickers, setSelectedStock } from "../../store";
import { useApiCall } from "../../hooks/useApiCall";
import { getAllStockTickers } from "../../services";
import { IoSearch } from "react-icons/io5";

const SearchBar = () => {
  const { allStocks, selectedStock } = useSelector((state) => state.stocks);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedTicker, setSelectedTicker] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasSelectedTicker, setHasSelectedTicker] = useState(false);
  const resultRefs = useRef([]); // Ref array for each result item
  const [
    fetchAllStockTickersFn,
    stockTickersData,
    ,
    ,
    isAllSTockTickerFetched,
  ] = useApiCall(getAllStockTickers);

  useEffect(() => {
    fetchAllStockTickersFn();
  }, []);

  useEffect(() => {
    if (isAllSTockTickerFetched) {
      dispatch(setAllStockTickers(stockTickersData.results));
    }
  }, [isAllSTockTickerFetched, stockTickersData, dispatch]);

  const fetchTickers = async () => {
    const fetchedResults = allStocks
      ? Object.keys(allStocks)
          ?.filter((ticker) => {
            const [country, companyName] = allStocks[ticker];
            return (
              companyName.toLowerCase().includes(query.toLowerCase()) ||
              ticker.toLowerCase().includes(query.toLowerCase())
            );
          })
          ?.map((ticker) => {
            const [country, companyName] = allStocks[ticker];
            return { ticker, company_name: companyName, country }; // Format the result for display
          })
      : [];

    setResults(fetchedResults);
    if (fetchedResults?.length > 0) {
      setSelectedIndex(0); // Select the first element by default
    } else {
      setSelectedIndex(-1); // Reset when no results
    }
  };

  useEffect(() => {
    if (!selectedStock) {
      setQuery("");
      setSelectedTicker("");
      setResults([]);
      setHasSelectedTicker(false); // Reset the selection flag
    } else {
      setQuery("");
      setSelectedTicker(selectedStock.company_name);
      setResults([]);
      setHasSelectedTicker(true);
    }
  }, [selectedStock]);
  useEffect(() => {
    if (query.length >= 1 && !hasSelectedTicker) {
      fetchTickers(query);
    }
  }, [query, hasSelectedTicker]);

  useEffect(() => {
    fetchTickers();
  }, [allStocks]);

  const handleSearchStockName = (item) => {
    dispatch(setSelectedStock(item));
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % results.length;
        if (resultRefs.current[newIndex]) {
          resultRefs.current[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + results.length) % results.length;
        if (resultRefs.current[newIndex]) {
          resultRefs.current[newIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
        return newIndex;
      });
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      const result = results[selectedIndex];
      setSelectedTicker(result?.company_name);
      handleSearchStockName(result);
      setQuery("");
      setResults([]);
      setHasSelectedTicker(true);
    } else if (
      (e.key === "Backspace" || e.key === "Delete") &&
      hasSelectedTicker
    ) {
      // Clear selected ticker and restore query editing
      setSelectedTicker("");
      setHasSelectedTicker(false);
      setQuery(e.target.value);
      handleSearchStockName(null);
      setResults([]);
    }
  };

  const handleResultClick = (result) => {
    setSelectedTicker(result?.company_name);
    handleSearchStockName(result);
    setQuery("");
    setResults([]);
    setHasSelectedTicker(true);
  };

  const clearInput = () => {
    setQuery("");
    setSelectedTicker("");
    handleSearchStockName(null);
    setResults([]);
    setHasSelectedTicker(false); // Reset the selection flag
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleClick = () => {
    if (query) {
      const result = results[selectedIndex];
      setSelectedTicker(result?.company_name);
      handleSearchStockName(result);
      setQuery("");
      setResults([]);
      setHasSelectedTicker(true);
    }
  };
  return (
    <>
      <div className={styles.searchBar}>
        <IoSearch size={16} color="#667085" />

        <input
          type="text"
          value={selectedTicker || query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="Start Typing Stock Name..."
          className={styles.input}
        />
        {selectedTicker && (
          <IoClose
            size={20}
            className={styles.clearIcon}
            onClick={clearInput}
          />
        )}
        {isInputFocused &&
          !hasSelectedTicker && // Show suggestions only when the input is focused and no ticker is selected
          results.length > 0 && (
            <div className={styles.resultsContainer}>
              {results.map((result, index) => (
                <div
                  key={result.ticker}
                  ref={(el) => (resultRefs.current[index] = el)} // Assign ref to each item
                  className={`${styles.resultItem} ${
                    index === selectedIndex && styles.selectedResultItem
                  }`}
                  onMouseDown={() => handleResultClick(result)} // Use onMouseDown to prevent input blur before click
                >
                  <div className={styles.ticker_info}>
                    <span className={styles.company_name}>
                      {result?.company_name}
                    </span>
                    <span className={styles.ticker_name}>
                      {" "}
                      {result?.ticker}
                    </span>
                  </div>
                  <div>{result?.country ?? "Not found"}</div>
                </div>
              ))}
            </div>
          )}
        {isInputFocused &&
          !hasSelectedTicker &&
          query.length > 0 &&
          results.length === 0 && (
            <div className={`${styles.resultsContainer} ${styles.noData}`}>
              Not Found
            </div>
          )}
      </div>
      <button
        style={{
          padding: "8px 16px",
          // border: "1px solid #EEF2FF",
          backgroundColor: "#EEF2FF",
          borderRadius: "8px",
          color: "#4F46E5",
          fontWeight: "600px",
          fontSize: "14px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "6px",
          border: " 1px solid #4f46e5",
        }}
        onClick={handleClick}
      >
        <span>Search</span>
        <IoSearch size={16} />
      </button>
    </>
  );
};

export default SearchBar;
