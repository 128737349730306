import React, { useEffect, useState } from "react";
import styles from "./uploadDocument.module.css";

import { LuUploadCloud } from "react-icons/lu";

import { setAllDocs, showToast } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { TIME_OPTIONS } from "../../utils/constants/common";
import FileList from "./FileList";
import { v4 as uuid } from "uuid";
import { useApiCall } from "../../hooks/useApiCall";
import { getAllDocs, uploadFiles } from "../../services";

const UploadDocument = ({ setSelectedOption, setModalVisible }) => {
  const [filesData, setFilesData] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // For drag feedback
  const dispatch = useDispatch();
  const [uploadFilesFn, , isUploading, , isUploaded] = useApiCall(uploadFiles);
  const { selectedStock } = useSelector((state) => state.stocks);

  const handleCloseModal = () => {
    setModalVisible(false);
    setFilesData([]);
  };

  const handleFileSelect = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf"; // Only allow PDF files
    input.multiple = true;
    input.onchange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      processFiles(selectedFiles);
    };
    input.click();
  };

  // File validation logic
  const processFiles = (selectedFiles) => {
    // Filter to only keep PDF files
    const pdfFiles = selectedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (pdfFiles.length !== selectedFiles.length) {
      dispatch(showToast("Only PDF files are allowed."));
    }

    // Filter files by size limit
    const validFiles = pdfFiles.filter((file) => file.size <= 20 * 1024 * 1024);

    if (validFiles.length !== pdfFiles.length) {
      dispatch(
        showToast(
          "Some files were not added due to exceeding the 20MB size limit."
        )
      );
    }

    const newFilesData = validFiles.map((file) => ({
      id: uuid(),
      file,
      type: "broker_research",
      stock: null, // Initialize the stock field
      isSelected: true,
    }));

    setFilesData((prevFilesData) => [...prevFilesData, ...newFilesData]);
  };

  const handleSubmit = async () => {
    try {
      if (filesData.length === 0) {
        throw new Error("Please upload files");
      }

      const isStockMissing = filesData.some((file) => !file.stock);
      if (isStockMissing) {
        throw new Error("Please add stock to all files before uploading.");
      }

      const finalFileData = filesData.map((file) => ({
        file: file.file,
        type: file.type,
        stock: file.stock.ticker,
      }));
      await uploadFilesFn(finalFileData);
      const data = await getAllDocs({ stock_ticker: selectedStock?.ticker });
      dispatch(setAllDocs(data?.data?.documents));
    } catch (error) {
      dispatch(
        showToast(error.message || "Something went wrong during the upload.")
      );
      if (error.statusCode) {
        setModalVisible(false);
        setFilesData([]);
      }
    } finally {
      setSelectedOption(TIME_OPTIONS.ALL_TIME);
    }
  };

  useEffect(() => {
    if (isUploaded) {
      setFilesData([]);
      setModalVisible(false);
    }
  }, [isUploaded, setModalVisible]);

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true); // Show visual feedback
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false); // Remove visual feedback
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    processFiles(droppedFiles); // Handle dropped files
  };
  if (isUploading) {
    return (
      <div className={styles.loader_wrapper}>
        <div className={styles.loader_content}>
          <p>Files are uploading...</p>
          <div className={styles.loader}></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.modal}  ${isDragging ? styles.drag_active : ""}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h2 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
            Upload File
          </h2>
          <span className={styles.close} onClick={handleCloseModal}>
            &times;
          </span>
        </div>

        {filesData.length > 0 ? (
          <FileList filesData={filesData} setFilesData={setFilesData} />
        ) : (
          filesData.length <= 0 && (
            <div className={styles.upload_box} onClick={handleFileSelect}>
              <div className={styles.upload_icon}>
                <div className={styles.upload_icon_inner}>
                  <LuUploadCloud size={24} />
                </div>
              </div>
              <p>
                <strong className={styles.upload_text}>Click to upload</strong>{" "}
                or drag and drop <br />
                PDF (max. 20MB)
              </p>
            </div>
          )
        )}
        <div className={styles.submit_btn}>
          {filesData.length > 0 && (
            <button className={styles.btn} onClick={handleFileSelect}>
              Add More
            </button>
          )}
          <button className={styles.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
