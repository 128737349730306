const Text = ({ text, bold, textStyle }) => {
  const styles = {
    text: {
      fontSize: 14,
      fontWeight: bold ? 600 : 400,
      ...textStyle,
    },
  };

  return <span style={styles.text}>{text}</span>;
};

export default Text;
