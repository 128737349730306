import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const getChatHistory = async (chat_id, limit = 20, offset = 0) => {
  const url = apiEndpoints.GET_CHAT_HISTORY(chat_id);

  const token = store.getState().user.token;
  return await api.get(url, {
    params: { limit, offset },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
