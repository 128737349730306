import React, { useEffect, useRef, useState } from "react";
import styles from "./uploadDocument.module.css";
import { FILE_OPTIONS } from "../../utils/constants/common";
import SelectionOption from "../SelectOption";
import { MdDelete } from "react-icons/md";
import FileItem from "./FileItem";
import { useSelector } from "react-redux";

const FileList = ({ setFilesData, filesData }) => {
  const searchRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [globalType, setGlobalType] = useState("");
  const [globalStock, setGlobalStock] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { allStocks } = useSelector((state) => state.stocks);
  const [filteredStocks, setFilteredStocks] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsFocused(false); // Hide suggestions if clicking outside the search area
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchText) {
      const filtered = Object.keys(allStocks)
        .filter((key) => {
          const [symbol, name] = allStocks[key];
          return (
            key.toLowerCase().includes(searchText.toLowerCase()) || // Check the key (ID)
            symbol.toLowerCase().includes(searchText.toLowerCase()) || // Check the symbol
            name.toLowerCase().includes(searchText.toLowerCase()) // Check the name
          );
        })
        .map((key) => ({
          ticker: key,
          country: allStocks[key][0],
          company_name: allStocks[key][1],
        }));
      setFilteredStocks(filtered); // Store the filtered results
    } else {
      setFilteredStocks([]); // Reset if input is cleared
    }
  }, [searchText, allStocks]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setFilesData((prev) =>
      prev.map((file) => ({
        ...file,
        isSelected: isChecked,
      }))
    );
  };

  const handleFileSelection = (id, isSelected) => {
    setFilesData((prev) =>
      prev.map((file) => (file.id === id ? { ...file, isSelected } : file))
    );
  };

  const handleTopLevelTypeChange = (newType) => {
    setGlobalType(newType);
    setFilesData((prev) =>
      prev.map((file) => (file.isSelected ? { ...file, type: newType } : file))
    );
  };

  const handleTopLevelStockChange = (newStock) => {
    setGlobalStock(newStock.company_name);
    setFilesData((prev) =>
      prev.map((file) =>
        file.isSelected ? { ...file, stock: newStock } : file
      )
    );
  };

  const handleFileTypeChange = (id, newType) => {
    setFilesData((prev) =>
      prev.map((file) => (file.id === id ? { ...file, type: newType } : file))
    );
  };

  const handleFileStockChange = (id, newStock) => {
    setFilesData((prev) =>
      prev.map((file) => (file.id === id ? { ...file, stock: newStock } : file))
    );
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase(); // Convert input to lowercase for
    setSearchText(value);
  };

  const handleFileDelete = (id) => {
    setFilesData((prev) => prev.filter((file) => file.id !== id));
  };

  return (
    <div className={styles.files}>
      <div className={styles.file_upload_header}>
        <div className={styles.file_options}>
          <input
            type="checkbox"
            onChange={handleSelectAll}
            id="selectAll"
            checked={filesData.every((file) => file.isSelected)}
            className={styles.checkbox}
          />
          <label htmlFor="selectAll" className={styles.file_name}>
            Select all
          </label>
        </div>
        <div className={styles.file_options}>
          <div className={styles.stock_search} ref={searchRef}>
            {globalStock ? (
              <div
                style={{
                  fontSize: "14px",
                  position: "relative",
                  border: "1px solid gray",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <span
                  style={{
                    maxWidth: "90%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "flex",
                  }}
                >
                  {globalStock}
                </span>
                <span
                  style={{
                    position: "absolute",
                    right: 5,
                    top: "50%",
                    transform: "translate(0,-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => setGlobalStock(null)}
                >
                  X
                </span>
              </div>
            ) : (
              <input
                type="text"
                style={{
                  padding: "5px",
                  outline: "none",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  outlineColor: "gray",
                }}
                className={styles.stock_search_input}
                value={searchText}
                onChange={handleSearchChange}
                onFocus={() => setIsFocused(true)} // Show suggestions on focus
                placeholder="Search stocks..."
              />
            )}
            {isFocused &&
              !globalStock &&
              (filteredStocks.length > 0 ? (
                <div className={styles.resultsContainer}>
                  {filteredStocks.map((result, index) => (
                    <div
                      key={result.ticker}
                      className={`${styles.resultItem}`}
                      onClick={() => handleTopLevelStockChange(result)}
                    >
                      <span className={styles.company_name}>
                        {result?.company_name} ({result.country})
                      </span>
                      <span className={styles.ticker_name}>
                        {" "}
                        {result?.ticker}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                searchText.length > 0 && (
                  <div className={styles.resultsContainer}>Not found</div>
                )
              ))}
          </div>
          <SelectionOption
            options={FILE_OPTIONS}
            onChange={(value) => handleTopLevelTypeChange(value)}
            selectedValue={globalType}
          />
          <MdDelete color="gray" size={20} style={{ visibility: "hidden" }} />
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.files_wrapper}>
        {filesData.map((data, index) => (
          <FileItem
            onDeleteFile={() => handleFileDelete(data.id)}
            key={data.id}
            data={data}
            onSelect={(isSelected) => handleFileSelection(data.id, isSelected)}
            onTypeChange={(newType) => handleFileTypeChange(data.id, newType)}
            onStockChange={(newStock) =>
              handleFileStockChange(data.id, newStock)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default FileList;
