import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const getAllDocs = async ({ stock_ticker, start_date, end_date }) => {
  const url = apiEndpoints.GET_ALL_DOCS;
  const token = store.getState().user.token;

  const body = {
    stock_ticker: stock_ticker ? stock_ticker : "",
    start_date: start_date ? start_date : "",
    end_date: end_date ? end_date : "",
  };
  return await api.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
