import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const uploadFiles = async (filesData) => {
  const url = apiEndpoints.FILE_UPLOAD;
  const token = store.getState().user.token;
  const formData = new FormData();

  filesData.forEach((data) => {
    formData.append("files", data.file);
    formData.append("categories", data.type);
    formData.append("stocks", data.stock);
  });
  return await api.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
