import { useCallback, useEffect, useRef, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { LuZoomOut, LuZoomIn } from "react-icons/lu";
import { MdClose } from "react-icons/md";
import { setClosePdf } from "../../../store";
import { useDispatch } from "react-redux";

import styles from "./pdfViewer.module.css";
import { useSelector } from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const resizeObserverOptions = {};

const maxWidth = 800;

const PdfViewer = () => {
  const dispatch = useDispatch();
  const { fileLink, targetPage, targetContent } = useSelector(
    (state) => state.docs
  );
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();
  const [scale, setScale] = useState(1);
  // const pageRef = useRef(null);

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  //  new code
  // const searchText =
  //   "he collapse of Barings Bank was a commercial catastrophe that resonated worldwide, showing what kind of secrets can lie behind an apparently successful organization. Following Nick Leeson’s arrest and subsequent conviction for fraud, investment banks anxiously r";
  // const targetPage = 2;

  const [highlightRange, setHighlightRange] = useState({ start: -1, end: -1 });
  const [textItems, setTextItems] = useState([]); // Store text items

  const textRenderer = useCallback(
    (textItem) => {
      const { start, end } = highlightRange;

      // Find the corresponding item from the `textItems` array using the index
      const itemIndex = textItems.findIndex(
        (item) => item.str === textItem.str
      );
      if (itemIndex >= start && itemIndex <= end) {
        return highlightPattern(textItem.str, textItem.str);
      }

      return textItem.str; // Render normally if outside the highlight range
    },
    [targetContent, highlightRange, textItems] // Now depends on textItems
  );

  const processTextForHighlighting = useCallback((items, pattern) => {
    let cumulativeTextStart = "";
    let cumulativeTextEnd = "";
    let searchStartIndex = -1;
    let searchEndIndex = -1;

    // Remove spaces from pattern for matching
    const lowerPattern = pattern.toLowerCase().replace(/\s+/g, ""); // Remove all spaces in the pattern

    // First pass: loop from the start to find the searchEndIndex
    for (let i = 0; i < items.length; i++) {
      let currentText = items[i].str.trim(); // Trim leading/trailing spaces from current text
      if (!currentText) continue; // Skip empty strings

      if (cumulativeTextStart) {
        cumulativeTextStart += " "; // Add a single space between items
      }

      cumulativeTextStart += currentText; // Accumulate text

      // Remove all spaces from accumulated text
      const lowerCumulativeTextStart = cumulativeTextStart
        .toLowerCase()
        .replace(/\s+/g, "");

      // Check if the entire pattern (without spaces) is in the accumulated text
      if (lowerCumulativeTextStart.includes(lowerPattern)) {
        searchEndIndex = i; // Set the end index to current item index
        break;
      }
    }

    // Second pass: loop from the end to find the searchStartIndex
    for (let i = items.length - 1; i >= 0; i--) {
      let currentText = items[i].str.trim(); // Trim leading/trailing spaces from current text
      if (!currentText) continue; // Skip empty strings

      if (cumulativeTextEnd) {
        currentText += " "; // Add a single space between items
      }

      cumulativeTextEnd = currentText + cumulativeTextEnd; // Prepend text

      // Remove all spaces from accumulated text
      const lowerCumulativeTextEnd = cumulativeTextEnd
        .toLowerCase()
        .replace(/\s+/g, "");

      // Check if the entire pattern (without spaces) is in the accumulated text
      if (lowerCumulativeTextEnd.includes(lowerPattern)) {
        searchStartIndex = i; // Set the start index to current item index
        break;
      }
    }

    return { searchStartIndex, searchEndIndex };
  }, []);

  const onPageLoadSuccess = useCallback(
    async (page) => {
      const textContent = await page.getTextContent();

      // Get the highlight range (start and end indices)
      if (targetContent) {
        const { searchStartIndex, searchEndIndex } = processTextForHighlighting(
          textContent.items,
          targetContent
        );
        setHighlightRange({ start: searchStartIndex, end: searchEndIndex });
      }

      // Store the indexed text items
      setTextItems(
        textContent.items.map((item, index) => ({ ...item, index }))
      );
    },
    [targetContent, processTextForHighlighting]
  );

  // useEffect(() => {
  //   // if (pageRef.current) {
  //   //   pageRef.current.scrollIntoView({ behavior: "smooth" });
  //   // }
  //   setNumPages(targetPage);
  // }, []);

  return (
    <>
      <div className={styles.pdf_viewer__info}>
        <h2>PDF Viewer</h2>
        <div className={styles.pdf_viewer__controls}>
          <LuZoomIn onClick={handleZoomIn} size={20} />
          <LuZoomOut onClick={handleZoomOut} size={20} />

          <span className={styles.pdf_close_btn}>
            <MdClose onClick={() => dispatch(setClosePdf())} size={20} />
          </span>
        </div>
      </div>
      <div className={styles.pdf_view} ref={setContainerRef}>
        <Document file={fileLink} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => {
            if (index + 1 === targetPage) {
              return (
                <Page
                  inputRef={(ref) => {
                    if (ref && targetPage === index + 1) {
                      ref.scrollIntoView();
                    }
                  }}
                  onLoadSuccess={onPageLoadSuccess}
                  customTextRenderer={textRenderer}
                  pageNumber={targetPage}
                  width={
                    containerWidth
                      ? Math.min(containerWidth, maxWidth) * scale
                      : maxWidth * scale
                  }
                />
              );
            }
            return (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={
                  containerWidth
                    ? Math.min(containerWidth, maxWidth) * scale
                    : maxWidth * scale
                }
              />
            );
          })}
        </Document>
      </div>
    </>
  );
};

export default PdfViewer;

function highlightPattern(text, pattern) {
  const regex = new RegExp(`(${pattern})`, "gi");
  return text.replace(regex, (value) => `<mark>${value}</mark>`);
}
