import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/auth/login";
import Chat from "./screens/chat";
import NotFound from "./screens/notFound";
import ProtectedRoute from "./layouts/ProtectedRoute";

import Toast from "./components/toast";
import ChatWindow from "./components/chatSectionComponents/chatWindow";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/app" element={<ProtectedRoute element={<Chat />} />}>
            <Route index element={<ChatWindow />} />
            <Route path=":chatId" element={<ChatWindow />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Toast />
    </>
  );
};

export default App;
