import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsSendFill } from "react-icons/bs";
import { PiSparkleFill } from "react-icons/pi";

import styles from "./messagePrompt.module.css";
import { apiEndpoints } from "../../../utils/constants/apiEndPoints";

import {
  addUserMessage,
  appendToStreamingBotMessage,
  finalizeBotMessage,
  setAllChats,
  startStreamingBotMessage,
} from "../../../store";
import { getAllChats } from "../../../services";
import { useApiCall } from "../../../hooks/useApiCall";
import { useNavigate } from "react-router-dom";

const MessagePrompt = ({ chatId }) => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [content, setContent] = useState(""); // State to track the editor content
  const [isFocused, setIsFocused] = useState(false); // State to track focus state

  const dispatch = useDispatch();
  const [isMessageSending, setIsMessageSending] = useState(false);
  const { selectedStock } = useSelector((state) => state.stocks);
  const { selectedDocs, selectedCategories } = useSelector(
    (state) => state?.docs
  );
  const { token } = useSelector((state) => state.user);

  const [getAllChatsFn, { chats }, , , isSuccess] = useApiCall(getAllChats);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAllChats(chats));
    }
  }, [isSuccess, chats, dispatch]);

  const sendMessageHandler = async () => {
    setIsMessageSending(true);
    dispatch(addUserMessage(content));
    dispatch(startStreamingBotMessage());

    try {
      const url = apiEndpoints.SEND_CHAT_MESSAGE;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...(chatId && { chat_id: chatId }),
          message: content.trim(),
          stock_ticker: selectedStock?.ticker,
          document_ids: selectedDocs,
          categories: selectedCategories,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to send message:", response.status, errorText);
        // todo: show message failed
        throw new Error("Failed to send message: " + errorText);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let partialMessage = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        const lines = chunk.split("\n").filter((line) => line.trim() !== "");
        for (const line of lines) {
          if (line.startsWith("data:")) {
            const dataString = line.substring(5).trim();
            if (dataString) {
              try {
                const eventData = JSON.parse(dataString);

                if (eventData.event === "token") {
                  partialMessage += eventData.data;
                  dispatch(appendToStreamingBotMessage(partialMessage));
                } else if (eventData.event === "end") {
                  dispatch(
                    finalizeBotMessage(eventData.data.messages[1].sources)
                  );
                  //  refetch all chats names and add chat id in ui
                } else if (eventData.event === "start") {
                  if (!chatId) {
                    getAllChatsFn();

                    navigate(`/app/${eventData.data.chat_id}?isNewChat=true`);
                  }
                }
              } catch (jsonError) {
                console.error(
                  "JSON parsing error:",
                  jsonError,
                  "Data:",
                  dataString
                );
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsMessageSending(false);
    }
  };
  const isInputDisabled =
    selectedStock == null &&
    selectedDocs?.length === 0 &&
    selectedCategories.length === 0 &&
    !chatId;

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
      setContent(""); // Clear the state
      if (editorRef.current) {
        editorRef.current.innerHTML = ""; // Explicitly clear the content
      }
    }
  };

  const handleSubmit = () => {
    if (isMessageSending) {
      return;
    }
    if (content.trim()) {
      sendMessageHandler();
      setContent("");
      if (editorRef.current) {
        editorRef.current.innerHTML = ""; // Explicitly clear the content
      }
    }
  };

  const handleInput = (event) => {
    const inputContent = event.target.innerHTML;
    setContent(inputContent); // Ensure no transformations reverse the text
  };
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    // If the content is empty, remove focus styling
    if (content.trim() === "") setIsFocused(false);
  };

  return (
    <div className={styles.prompt}>
      <span className={styles.icon}>
        <PiSparkleFill color="#4F46E5" />
      </span>

      <div
        className={`${styles.editor_container} ${
          isFocused || content ? styles.focused : ""
        }`}
        style={{
          cursor: isInputDisabled ? "not-allowed" : "text",
        }}
        ref={editorRef}
        onKeyDown={handleKeyPress}
        contentEditable={!isInputDisabled}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-placeholder={
          isInputDisabled
            ? "Please select stock ticker or documents"
            : "Ask Questions"
        }
        dir="ltr"
      ></div>

      {isMessageSending ? (
        <span className={` ${styles.loader}`}></span>
      ) : (
        <span className={styles.send_btn} onClick={handleSubmit}>
          <BsSendFill color="#4F46E5" />
        </span>
      )}
    </div>
  );
};

export default MessagePrompt;
