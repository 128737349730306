import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);

  if (!isUserLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default ProtectedRoute;
