import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const getSuggestedQuestions = async (chat_id = null) => {
  const url = apiEndpoints.GET_CHAT_MESSAGE_SUGGESTIONS(chat_id);
  const token = store.getState().user.token;
  return await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
