import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const getAllChats = async (filters) => {
  const url = apiEndpoints.GET_ALL_CHATS;
  const token = store.getState().user.token;
  return await api.get(url, {
    params: filters,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
