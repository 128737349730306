import GoogleLoginComponent from "../../../components/GoogleLoginComponent";
import styles from "./login.module.css";
import munshotLogo from "../../../assets/logo.png";
import Avatar from "../../../components/Avatar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FORM_LINK } from "../../../utils/constants/common";
import { useEffect } from "react";
const Login = () => {
  const { isUserLoggedIn, error } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/app");
    }
  }, [isUserLoggedIn, navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content_wrapper}>
        <Avatar
          src={munshotLogo}
          alt="Logo"
          customStyle={{ maxWidth: "250px" }}
        />
        <div className={styles.heading}>Welcome to Munshot</div>

        <div>
          <GoogleLoginComponent />
        </div>
      </div>
      {error && (
        <p className={styles.description}>
          Thank you for your interest in our product - Please fill this{" "}
          <a target="_blank" rel="noreferrer" href={FORM_LINK}>
            form
          </a>{" "}
          to book a demo. Or contact{" "}
          <a href="mailto:ceekay@muns.io">ceekay@muns.io</a> for getting access
          to our product.
        </p>
      )}
    </div>
  );
};

export default Login;
