import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("access_token");
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  isUserLoggedIn: !!token,
  userInfo: userInfo,
  token: token || null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.isUserLoggedIn = false;
      state.userInfo = null;
      state.token = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("userInfo");
    },
    setLoginUser: (state, action) => {
      const { access_token, user_id, profile_pic, name } = action.payload;
      state.isUserLoggedIn = true;
      state.token = access_token;
      state.userInfo = {
        user_id,
        name,
        profile_pic,
      };

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
  },
});

export const { logoutUser, setLoginUser } = userSlice.actions;
export default userSlice.reducer;
