import { useState, useCallback } from "react";

export function useApiCall(apiFunction) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const runApiCall = useCallback(
    async (params) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await apiFunction(params);
        setIsSuccess(true);
        setData(response.data); // assuming response has a `data` property
      } catch (err) {
        const errorMessage =
          err.response?.data?.message || "Something went wrong";

        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [apiFunction]
  );
  return [runApiCall, data, isLoading, error, isSuccess];
}
