import React, { useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import config from "../utils/config";
import { useApiCall } from "../hooks/useApiCall";
import { loginUser } from "../services";
import { setLoginUser } from "../store";

import { useDispatch } from "react-redux";
import { showToast } from "../store";

const GoogleLoginComponent = () => {
  const [loginUserFn, userData, isLogging, loginError, loginSucceed] =
    useApiCall(loginUser);
  const dispatch = useDispatch();

  // todo: show loading line to make interative
  useEffect(() => {
    if (loginSucceed) {
      dispatch(setLoginUser(userData));
    }
  }, [loginSucceed, dispatch, userData]);
  useEffect(() => {
    if (loginError) {
      dispatch(showToast(loginError));
    }
  }, []);

  const handleLoginSuccess = async (response) => {
    const credential = response.credential;
    loginUserFn(credential);
  };

  const handleLoginFailure = (response) => {
    dispatch(showToast("Login Failed"));
    // TODO: Add some toast with error message...
  };

  return (
    <div>
      <GoogleOAuthProvider clientId={config.GOOGLE_AUTH_CLIENT_ID}>
        <GoogleLogin
          width={"300px"}
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFailure}
          cookiePolicy={"single_host_origin"}
          shape="rectangular"
          logo_alignment="center"
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginComponent;
