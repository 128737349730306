import MessagePrompt from "../messagePrompt";
import ChatHeader from "../chatHeader";
import SuggestionBox from "../suggestionBox";
import ChatMessages from "../chatMessages";
import { useParams } from "react-router-dom";

const ChatWindow = () => {
  const { chatId } = useParams();

  return (
    <>
      <ChatHeader />
      <ChatMessages chatId={chatId} key={`${chatId}-chat-messages`} />
      <SuggestionBox chatId={chatId} key={`${chatId}-suggestion-box`} />
      <MessagePrompt chatId={chatId} key={`${chatId}-message-prompt`} />
    </>
  );
};

export default ChatWindow;
