import React from "react";
import styles from "./skeleton.module.css";

const Skeleton = ({
  width = "250px",
  height = "250px",
  gap = "10px",
  count = 10,
  direction = "column",
  customStyle = {},
}) => {
  return (
    <LinearSkeleton
      width={width}
      height={height}
      gap={gap}
      count={count}
      direction={direction}
      customStyle={customStyle}
    />
  );
};

export default Skeleton;

const LinearSkeleton = ({
  width,
  height,
  gap,
  count,
  direction,
  customStyle,
}) => {
  return (
    <div
      className={styles.skeleton_wrapper}
      style={{
        gap: gap,
        flexDirection: direction,
        ...customStyle,
      }}
    >
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className={styles.skeleton}
          style={{
            width: width,
            height: height,
          }}
        ></div>
      ))}
    </div>
  );
};
