import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import styles from "../styles/filter.module.css";
import { TIME_OPTIONS } from "../utils/constants/common";

dayjs.extend(utc);
dayjs.extend(quarterOfYear);

const Filter = ({ selectedOption, filterHandler }) => {
  const calculateDateRange = (option) => {
    let endDate = dayjs().utc().format(); // Default end date is now
    let startDate = null;

    switch (option) {
      case TIME_OPTIONS.TWO_YEARS:
        startDate = dayjs().utc().subtract(2, "year").format();
        break;
      case TIME_OPTIONS.ONE_YEAR:
        startDate = dayjs().utc().subtract(1, "year").format();
        break;
      case TIME_OPTIONS.LAST_QUARTER:
        startDate = dayjs()
          .utc()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format();
        endDate = dayjs()
          .utc()
          .subtract(1, "quarter")
          .endOf("quarter")
          .format();
        break;
      case TIME_OPTIONS.THIS_QUARTER:
        startDate = dayjs().utc().startOf("quarter").format();
        break;
      case TIME_OPTIONS.SEVEN_DAYS:
        startDate = dayjs().utc().subtract(7, "day").format();
        break;
      case TIME_OPTIONS.ALL_TIME:
        startDate = null;
        break;
      default:
        startDate = null;
        break;
    }

    return { start_date: startDate, end_date: endDate };
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    const dateRange = calculateDateRange(option);
    filterHandler({ option, dateRange });
  };

  return (
    <div className={styles.container}>
      <div className={styles.radioGroup}>
        {Object.values(TIME_OPTIONS).map((option, index) => (
          <React.Fragment key={option}>
            <label className={styles.radioOption}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                className={styles.radioButton}
              />
              {option}
            </label>
            {index !== Object.values(TIME_OPTIONS).length - 1 && (
              <hr className={styles.hr} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Filter;
