import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../store";
import styles from "./toast.module.css";
import { MdError } from "react-icons/md";

const Toast = () => {
  const dispatch = useDispatch();
  const { message, isVisible } = useSelector((state) => state.toast);
  const [visible, setVisible] = useState(false);
  // i added this state to show animation

  useEffect(() => {
    if (isVisible) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false); // Start the hide animation
        setTimeout(() => {
          dispatch(hideToast()); // Hide toast after animation
        }, 500); // Wait for the hide animation to finish before hiding the toast completely
      }, 3000); // Auto dismiss after 3 seconds

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [isVisible, dispatch]);

  if (!message) return null;

  return (
    <div className={`${styles.wrapper} ${visible ? styles.show : styles.hide}`}>
      <MdError />
      <span>{message}</span>
    </div>
  );
};

export default Toast;
