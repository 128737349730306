import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  variant: "default",
  isVisible: false,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action) => {
      if (state.isVisible) return;
      state.message = action.payload;
      state.isVisible = true;
    },
    hideToast: (state) => {
      state.isVisible = false;
      state.message = null;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
