import React from "react";

const SelectionOption = ({
  options,
  onChange,
  selectedValue = "",
  label = "",
  customStyle,
}) => {
  return (
    <div className="selection-option-wrapper">
      {label && <label className="selection-option-label">{label}</label>}
      <select
        className="selection-option-select"
        value={selectedValue}
        onChange={(e) => onChange(e.target.value)}
        style={{
          padding: "5px",
          outline: "none",
          border: "1px solid gray",
          borderRadius: "5px",
          outlineColor: "gray",
          ...customStyle,
        }}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectionOption;
