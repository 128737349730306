import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import api from "../api";
import store from "../../store";

export const getAllStockTickers = async () => {
  const url = apiEndpoints.GET_ALL_STOCK_TICKERS;
  const token = store.getState().user.token;
  const body = {
    query: "",
  };
  return await api.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
