import { useEffect } from "react";
import { useApiCall } from "../../../hooks/useApiCall";
import { getSuggestedQuestions } from "../../../services";
import "./index.css";

import { BsSendFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserMessage,
  appendToStreamingBotMessage,
  finalizeBotMessage,
  startStreamingBotMessage,
} from "../../../store";
import { apiEndpoints } from "../../../utils/constants/apiEndPoints";

const SuggestionBox = ({ chatId }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { selectedStock } = useSelector((state) => state.stocks);
  const { selectedDocs, selectedCategories } = useSelector(
    (state) => state?.docs
  );

  const { messages } = useSelector((state) => state.chats);
  const [fetchSuggestedQuestions, questions, , , isSuggestedQuestionsFetched] =
    useApiCall(getSuggestedQuestions);

  useEffect(() => {
    if (chatId && messages.length) {
      setTimeout(() => {
        fetchSuggestedQuestions(chatId);
      }, 2000);
    }
  }, [chatId, fetchSuggestedQuestions, messages.length]);

  const sendMessageHandler = async (content) => {
    dispatch(addUserMessage(content));
    dispatch(startStreamingBotMessage());

    try {
      const url = apiEndpoints.SEND_CHAT_MESSAGE;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...(chatId && { chat_id: chatId }),
          message: content.trim(),
          stock_ticker: selectedStock?.ticker,
          document_ids: selectedDocs,
          categories: selectedCategories,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to send message:", response.status, errorText);
        // todo: show message failed
        throw new Error("Failed to send message: " + errorText);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let partialMessage = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        const lines = chunk.split("\n").filter((line) => line.trim() !== "");
        for (const line of lines) {
          if (line.startsWith("data:")) {
            const dataString = line.substring(5).trim();
            if (dataString) {
              try {
                const eventData = JSON.parse(dataString);

                if (eventData.event === "token") {
                  partialMessage += eventData.data;
                  dispatch(appendToStreamingBotMessage(partialMessage));
                } else if (eventData.event === "end") {
                  dispatch(
                    finalizeBotMessage(eventData.data.messages[1].sources)
                  );
                  //  refetch all chats names and add chat id in ui
                }
              } catch (jsonError) {
                console.error(
                  "JSON parsing error:",
                  jsonError,
                  "Data:",
                  dataString
                );
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const SuggestionItem = ({ data, lastItem }) => {
    return (
      <div className="suggestion-item" onClick={() => sendMessageHandler(data)}>
        <p className="suggestion-item-text">{data}</p>
        <BsSendFill color="#4F46E5" />
      </div>
    );
  };
  return (
    <div className="suggestion-container">
      {isSuggestedQuestionsFetched &&
        questions
          ?.filter((item) => item?.length)
          ?.map((item, index) => (
            <SuggestionItem
              key={index}
              data={item}
              lastItem={questions?.length - 1 === index}
            />
          ))}
    </div>
  );
};

export default SuggestionBox;
