import React from "react";
import styles from "../styles/button.module.css";
import PropTypes from "prop-types";

const Button = ({ backgroundColor, textColor, buttonText, onClick }) => {
  const customStyle = {
    backgroundColor: backgroundColor || "#4F46E5",
    color: textColor || "white",
  };

  return (
    <button style={customStyle} className={styles.button} onClick={onClick}>
      {buttonText}
    </button>
  );
};

Button.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;
