export const FILE_OPTIONS = [
  { value: "broker_research", label: "Broker Research" },
  { value: "earning_calls", label: "Earning Calls" },
  { value: "financials", label: "Financials" },
  { value: "expert_calls", label: "Expert Calls" },
  { value: "news", label: "News" },
  { value: "others", label: "Others" },
];
export const FORM_LINK = "https://forms.gle/yG7WxgK1VUPx2mvi9";

export const TIME_OPTIONS = {
  ALL_TIME: "All Time",
  SEVEN_DAYS: "7 Days",
  ONE_YEAR: "1 Year",
  TWO_YEARS: "2 Years",
  LAST_QUARTER: "Last Quarter",
  THIS_QUARTER: "This Quarter",
};
