import React, { useEffect, useRef, useCallback } from "react";
import styles from "./chatMessages.module.css";
import "../../../styles/bounce.css";
import remarkGfm from "remark-gfm";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useApiCall } from "../../../hooks/useApiCall";
import { getChatHistory, getDocLink } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveChat,
  setMessages,
  setSelectCategories,
  setSelectDocs,
  setSelectedStock,
  setShowPdf,
  setTargets,
} from "../../../store";
import { FaFilePdf } from "react-icons/fa";

const ChatMessages = ({ chatId }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewChat = searchParams.get("isNewChat");
  const { allStocks } = useSelector((state) => state.stocks);
  const [
    fetchChatHistory,
    {
      messages: chatMessages,
      categories,
      documents_used,
      input_ticker,
      chat_title,
    },
    isAllChatLoading,
    ,
    isChatHistoryFetched,
  ] = useApiCall(getChatHistory);

  useEffect(() => {
    if (chatId && !isNewChat) {
      fetchChatHistory(chatId);
    }
  }, [chatId, fetchChatHistory]);

  useEffect(() => {
    setSearchParams({});
  }, [setSearchParams]);
  useEffect(() => {
    if (isChatHistoryFetched) {
      dispatch(setSelectCategories(categories));
      dispatch(setSelectDocs(documents_used.map((doc) => doc.document_id)));
      dispatch(setMessages(chatMessages));
      dispatch(
        setActiveChat({
          isActive: true,
          chatId: chatId,
          chatName: chat_title,
        })
      );
    }
  }, [
    categories,
    chatMessages,
    dispatch,
    documents_used,
    isChatHistoryFetched,
  ]);

  useEffect(() => {
    if (isChatHistoryFetched && input_ticker) {
      dispatch(
        setSelectedStock(
          allStocks[input_ticker]
            ? {
                ticker: input_ticker,
                company_name: allStocks[input_ticker][1],
                country: allStocks[input_ticker][0],
              }
            : null
        )
      );
    }
  }, [allStocks, dispatch, input_ticker, isChatHistoryFetched]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const allMessages = useSelector((state) => state.chats.messages);
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, scrollToBottom]);
  if (isAllChatLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading....
      </div>
    );
  }

  return <MessageList messages={allMessages} messagesEndRef={messagesEndRef} />;
};

export default ChatMessages;

const MessageList = ({
  messages,

  messagesEndRef,
}) => (
  <div className={styles.chat_messages}>
    {messages?.map((msg, index) => (
      <ChatMessage
        key={index}
        index={index}
        msg={msg}
        isBot={msg.role === "bot"}
      />
    ))}
    <div ref={messagesEndRef} />
  </div>
);

const ChatMessage = ({ index, msg, isBot }) => (
  <div
    key={index}
    className={`${styles.chat_message} ${isBot ? styles.bot : styles.user}`}
  >
    {isBot ? <Answer msg={msg} /> : <Question msg={msg} />}
  </div>
);

const Answer = ({ msg }) => {
  const dispatch = useDispatch();
  const [getDocLinkFn, docLink, , , isSuccess] = useApiCall(getDocLink);
  const { allDocs } = useSelector((state) => state.docs);
  const handleShowPdf = (data) => {
    getDocLinkFn(data.doc_id);
    dispatch(
      setTargets({
        targetPage: data.pageNumber,
        targetContent: data.pageContent,
      })
    );
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setShowPdf(docLink));
    }
  }, [isSuccess, docLink, dispatch]);
  // todo:make loading state different is msg.content length is 0 then it will show the evaluating your question and finding relevant details...
  return (
    <div className={styles.answer}>
      <div className={styles.answer_head}>
        <span>Answer</span>
      </div>
      <ReactMarkdown
        className={styles.reactMarkDown}
        remarkPlugins={[remarkGfm]}
        children={
          msg.content
            ? msg.content
            : "Evaluating your question and finding relevant details..."
        }
      />
      <div className={styles.sources}>
        {msg.sources &&
          msg.sources.length > 0 &&
          msg.sources?.map((src, i) => {
            const pdfTitle =
              allDocs
                ?.flatMap((category) => category.content)
                ?.find((doc) => doc.id === src.doc_id)?.title || null;
            return (
              <div className={styles.source} key={i}>
                <span
                  className={styles.source_info}
                  key={i}
                  onClick={() => handleShowPdf(src)}
                >
                  <FaFilePdf />

                  {pdfTitle?.length > 23
                    ? pdfTitle.slice(0, 23 - 3) + "..."
                    : pdfTitle}
                </span>
                <span className={styles.src_page}>Pg. No:{src.pageNumber}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Question = ({ msg }) => (
  <div className={styles.question}>
    {msg.content?.split("<br>").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ))}
  </div>
);
