import { configureStore } from "@reduxjs/toolkit";
import stocksReducer from "./slices/stocksSlice.js";
import chatsReducer from "./slices/chatsSlice.js";
import docsReducer from "./slices/docsSlice.js";
import userReducer from "./slices/userSlice.js";
import toastReducer from "./slices/toastSlice.js";

const store = configureStore({
  reducer: {
    stocks: stocksReducer,
    chats: chatsReducer,
    docs: docsReducer,
    user: userReducer,
    toast: toastReducer,
  },
});

export default store;

export * from "./slices/userSlice";
export * from "./slices/chatsSlice";
export * from "./slices/docsSlice";
export * from "./slices/stocksSlice";
export * from "./slices/toastSlice";
