import styles from "./chatHistory.module.css";
//assets
import { FaRegEdit } from "react-icons/fa";

import Text from "../../Text";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../skeleton";
import { useApiCall } from "../../../hooks/useApiCall";
import { getAllChats } from "../../../services";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  setActiveChat,
  setAllChats,
  setClosePdf,
  setMessages,
  setSelectCategories,
  setSelectDocs,
} from "../../../store";

// todo:fix the ellipsis property properly
const ChatHistory = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { chatId: activeChatId } = useParams();
  const { selectedStock } = useSelector((state) => state.stocks);
  const { allChats, isChatLoaded } = useSelector((state) => state.chats);
  const [getAllChatsFn, { chats }, isAllChatLoading, , isSuccess] =
    useApiCall(getAllChats);

  useEffect(() => {
    getAllChatsFn({
      stock_ticker: selectedStock?.ticker,
    });
  }, [getAllChatsFn, selectedStock]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAllChats(chats));
    }
  }, [chats, dispatch]);

  const createNewChat = () => {
    dispatch(setSelectCategories([]));
    dispatch(setSelectDocs([]));
    dispatch(setClosePdf());
    dispatch(setMessages([]));
    navigate("/app");
    dispatch(
      setActiveChat({
        isActive: false,
        chatId: null,
        chatName: "",
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Text text={"Chats"} bold={true} />
        <button
          onClick={() => {
            createNewChat();
          }}
          className={styles.new_chat_btn}
        >
          <span>New Chat</span>
          <FaRegEdit />
        </button>
      </div>
      {isAllChatLoading && !isChatLoaded ? (
        <Skeleton
          width="100%"
          height="30px"
          gap="15px"
          customStyle={{
            marginTop: "20px",
            padding: "0 10px",
          }}
        />
      ) : allChats?.length ? (
        <>
          {allChats?.map((item) => (
            <div className={styles.chats_wrapper} key={item?.monthValue}>
              <div className={styles.chat_month}>{item?.monthName} </div>
              <div className={styles.monthly_chats}>
                {item?.data?.map((dataItem) => (
                  <Link
                    className={`${styles.chat_item} ${
                      dataItem?.chat_id === activeChatId
                        ? styles.active_chat
                        : null
                    }`}
                    key={dataItem?.chat_id}
                    to={`/app/${dataItem?.chat_id}`}
                  >
                    {dataItem?.auto_generated_name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.new_chat}>New Chat +</div>
      )}
    </div>
  );
};

export default ChatHistory;
